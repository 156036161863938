.multiselect{
    position: relative;
    margin-bottom: 20px;
    & ul{
        position: absolute;
        background-color: #fff;
        width: 100%;
        z-index: 5;
        box-shadow: 0px 2px 4px rgb(0 0 0 / 29%);
        border-radius: 4px;
        & li{
            list-style: none;
            padding: 10px 15px;
            border-bottom: 1px solid rgb(0 0 0 / 10%);
            cursor: pointer;
            &:last-child{
                border: 0;
            }
        }
    }
    &Control{
        width: 100%;
        min-width: 0px;
        outline: 2px solid transparent;
        outline-offset: 2px;
        position: relative;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
        transition-property: var(--chakra-transition-property-common);
        transition-duration: var(--chakra-transition-duration-normal);
        font-weight: 400;
        font-size: var(--chakra-fontSizes-md);
        -webkit-padding-start: var(--chakra-space-4);
        padding-inline-start: var(--chakra-space-4);
        -webkit-padding-end: var(--chakra-space-4);
        padding-inline-end: var(--chakra-space-4);
        height: var(--chakra-sizes-10);
        border-radius: var(--chakra-radii-md);
        border: 1px solid;
        border-color: inherit;
        background: inherit;
        display: flex;
        align-items: center;
        input{
            border: 0px;
            box-shadow: unset;
            padding: 0;
            &:focus,&:hover{
            box-shadow: unset;
            border: 0;
            }
        }
    }
}
.error{
    &Field{
        border-color: #E53E3E;
        box-shadow: 0 0 0 1px #E53E3E;
    }
    &Text{
        color: var(--chakra-colors-red-500);
        font-size: var(--chakra-fontSizes-sm);
    }
}